import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/transport',
    name: 'Transport',
    component: () => import('../views/Transport.vue'),
  },
  {
    path: '/transport-tonejs',
    name: 'TransportToneJs',
    component: () => import('../views/TransportToneJs.vue'),
  },
  {
    path: '/svg',
    name: 'Svg',
    component: () => import('../views/SvgTest.vue'),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
