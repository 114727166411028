import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    audioState: null,
  },
  mutations: {
    AUDIO_STATE_SET(state, payload) {
      state.audioState = payload;
    },
  },
  actions: {
  },
  modules: {
  },
});
