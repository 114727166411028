<template>
  <div id="app">
    <div class="nav">
      <router-link to="/">Home</router-link>&nbsp;|&nbsp;
      <router-link to="/transport-tonejs">Tone.js Transport Timeline</router-link>&nbsp;|&nbsp;
      <router-link to="/transport">Transport Panel Component</router-link>&nbsp;|&nbsp;
      <router-link to="/svg">SVG Tests</router-link>
    </div>
    <div class="nav">
      <h4>Audio: {{ audioState }}</h4>
      <button v-if="audioState === 'suspended'" @click="startAudio">Start Audio</button>
    </div>
    <router-view v-if="1 || audioState === 'running'" />
  </div>
</template>

<script>
export default {
  name: 'App',

  components: {
  },

  data() {
    return {
    };
  },

  computed: {
    audioState() {
      return this.$store.state.audioState;
    },
  },

  methods: {
    async startAudio() {
      await this.$Tone.start();
      this.$store.commit('AUDIO_STATE_SET', this.$Tone.getContext().state);
      console.log('Audio started:', this.audioState);
    },
  },

  watch: {
  },

  created() {
    this.$store.commit('AUDIO_STATE_SET', this.$Tone.getContext().state);
  },
};
</script>

<style lang="scss">
html,
body {
  min-height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  padding: 20px;
}

.nav {
  padding: 10px;
  display: flex;

  & > div {
    padding-right: 10px;
    border-right: 1px solid #888;
    margin-right: 10px;
  }

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
